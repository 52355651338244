* {
  --card-width: 18rem;
}

.projects {
  height: 100%;
  margin: 3.5rem 0 0;
  z-index: 0;
}

.projectsContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  margin: 0 auto;
}

.projectsContent h2 {
  width: 90%;
  text-align: start;
  color: rgb(154, 154, 154);
  font-size: 2.5rem;
}
.cards {
  margin-top: 1vmax;

.card {
  display: flex;
  flex-direction: column;
  width: var(--card-width);
  height: 30rem;
  background-color: rgb(169, 202, 191);
  border-radius: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    height: 5.5rem;
    width: var(--card-width);
    background-color: rgb(54, 67, 67);
    border-radius: 10px 10px 0 0;
    -webkit-box-shadow: 0px 12px 20px -10px rgba(0, 0, 0, 0.85);
    box-shadow: 0px 12px 20px -10px rgba(0, 0, 0, 0.85);

    .title {
      color: aliceblue;
      font-size: 1.5rem;
      padding: 10px;
    }

    .date {
      color: antiquewhite;
      font-size: 1rem;
      padding: 0 10px;
    }

    .status {
      height: 10vh;
      padding: 10px;

      img {
        width: 32px;
        cursor: pointer;
      }
    }
  }

  .projectSS {
    width: var(--card-width);
    height: 10rem;
    img {
      width: var(--card-width);
      height: 10rem;
    }
  }

  .mainInfo {
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .description {
    padding: 10px;
    font-size: 1.1rem;
  }

  .tools {
    bottom: 2.2rem;
    padding: 10px;
    text-align: left;
  }
  .tools span {
    font-size: 1.5rem;
  }

  .footerIcons {
    bottom: 0;
    display: flex;
    gap: 1rem;
    padding: 10px;
    background-color: rgb(54, 67, 67);
  }
}
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 290px;
  height: 480px;
}

.swiper-pagination-bullet {
  background: rgb(109, 163, 158);
}