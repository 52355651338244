@import "../../../node_modules/include-media/dist/include-media";
$breakpoints: (
  phone: 320px,
  tablet: 768px,
  desktop: 1024px,
);

#intro {
  position: relative;
  margin-bottom: 3rem;
  height: 100%;
  z-index: 0;
}

//------------------
//-- Left/Right side text
//------------------

.sectionTextLeft {
  position: relative;
  min-height: 30rem;


  .firstLine {
    position: absolute;
    top: 8vmax;
    left: 10vmax;
    z-index: 1;
  }

  .key {
    font-size: 3.5vmax;
    color: blueviolet;
  }

  .name {
    font-size: 2.5vmax;
    color: rgb(90, 157, 215);
  }

  .openBracket1 {
    font-size: 2.3vmax;
    color: blueviolet;
  }

  .openBracket2,
  .closeBracket {
    color: blue;
    font-size: 2.3vmax;
  }

  .secondLine {
    position: absolute;
    top: 12.5vmax;
    left: 15.5vmax;
    font-size: 2.3vmax;
    color: rgb(161, 130, 53);
  }

  .thirdLine {
    position: absolute;
    top: 16vmax;
    left: 18.5vmax;
    font-size: 2.3vmax;
    color: whitesmoke;
    z-index: 1;

    span {
      color: rgb(161, 130, 53);
    }
  }

  .closeBracket {
    position: absolute;
    top: 18.5vmax;
    left: 15.5vmax;
  }

  @mixin adjust-left($initial-position) {
    left: calc(#{$initial-position} - 5vmax);
  }

  @include media(">=phone", "<=desktop") {
    .firstLine {
      @include adjust-left(10vmax);
    }
    .secondLine {
      @include adjust-left(15.5vmax);
    }
    .thirdLine {
      @include adjust-left(18.5vmax);
    }
  }
}


//--------------------------------
//-- Violin image + animated notes
//--------------------------------

.violinIMG {
  position: absolute;
  top: 18vmax;
  left: 55vmax;
  height: 20vmax;
  width: 35vmax;
  background: url("../../assets/images/notes/violin.png") center;
  background-size: cover;
  mask-image: radial-gradient(rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0.001) 70%);
  z-index: 1;
}

.musicNotes {
  position: absolute;
  top: 18vmax;
  left: 55vmax;
  height: 20vmax;
  width: 35vmax;

  img {
    max-width: 4vmax;
    max-height: 5vmax;
  }

  .note1,
  .note2,
  .note3,
  .note4,
  .note5 {
    position: absolute;
    animation: popNote 1s 3;
    opacity: 0;
  }

  .note1 {
    right: 3vmax;
    top: 7vmax;
    transform: translate3d(5vmax, -8vmax, 0);
  }
  .note2 {
    right: 8vmax;
    top: 3vmax;
    animation-delay: 1.2s;
    transform: translate3d(3vmax, -8vmax, 0);
  }
  .note3 {
    right: 13.5vmax;
    top: 2vmax;
    animation-delay: 0.6s;
    transform: translate3d(0, -8vmax, 0);
  }
  .note4 {
    right: 20.5vmax;
    top: 2vmax;
    animation-delay: 0.9s;
    transform: translate3d(-3vmax, -8vmax, 0);
  }
  .note5 {
    right: 27vmax;
    top: 7vmax;
    animation-delay: 0.3s;
    transform: translate3d(-5vmax, -8vmax, 0);
  }

  .rightSideContent {
    position: relative;
  }

  @keyframes popNote {
    0% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

@include media(">=phone", "<=desktop") {
  .violinIMG {
    top: 25vmax;
    left: 8vmax;
  }
}

//---------------------------------
//-- Laptop image + right side text
//---------------------------------

.keyboard {
  position: absolute;
  top: 18vmax;
  left: 10vmax;
  height: 20vmax;
  width: 35vmax;
  background: url("../../assets/images/keyboard.png") center;
  background-size: cover;
  mask-image: radial-gradient(rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0.001) 70%);
  z-index: 1;
}

.sectionTextRight {
  position: relative;
  min-height: 30vmax;

  .contentTextRight {
    position: relative;
    top: 6vmax;
  }

  .firstLineRight {
    position: absolute;
    top: 3vmax;
    right: 36vmax;
    font-size: 2.3vmax;
    color: rgb(161, 130, 53);

    span {
      color: blue;
    }
  }

  .closeBracketRight {
    position: absolute;
    top: 10vmax;
    right: 38.5vmax;
    color: blue;
    font-size: 2.3vmax;
  }

  .secondLineBracket {
    position: absolute;
    top: 14vmax;
    right: 40vmax;
    font-size: 2.3vmax;
    color: blueviolet;
  }

  .secondLineRight {
    position: absolute;
    top: 7vmax;
    right: 15vmax;
    font-size: 2.3vmax;
    color: whitesmoke;

    span {
      color: rgb(161, 130, 53);
    }
  }

  @mixin adjust-top($initial-position) {
    top: calc(#{$initial-position} - 7vmax);
  }

  @mixin adjust-right($initial-position) {
    right: calc(#{$initial-position} - 13vmax);
  }
  @include media(">=phone", "<=desktop") {
    .firstLineRight {
      @include adjust-top(3vmax);
      @include adjust-right(36vmax);
    }
    .closeBracketRight {
      @include adjust-top(10vmax);
      @include adjust-right(38.5vmax);
    }
    .secondLineBracket {
      @include adjust-top(14vmax);
      @include adjust-right(40vmax);
    }
    .secondLineRight {
      @include adjust-top(7vmax);
      @include adjust-right(15vmax);
    }
  }
}

//----------------------------------------
//-- Animation: circles, notes, .violinSVG
//----------------------------------------

.sectionAnimation {
  position: relative;
  width: 100vh;
  height: 100%;
}

.circlesLeft {
  position: relative;
  width: 13rem;
  height: 25rem;

  .smallCircleLeft {
    position: absolute;
    bottom: 7vh;
  }

  .bigCircleLeft {
    position: absolute;
  }

  #smallCircleLeft path {
    stroke-dasharray: 340.91;
    stroke-dashoffset: 340.91;
    animation: curve 6s ease-in-out infinite;
  }

  #bigCircleLeft path {
    stroke-dasharray: 542;
    stroke-dashoffset: 542;
    animation: curve 8s ease-in-out infinite;
  }

  @keyframes curve {
    to {
      stroke-dashoffset: 0;
      opacity: 0;
    }
  }
}

.note1 {
  position: absolute;
  right: 200px;
  top: 190px;
}
.note2 {
  position: absolute;
  right: 300px;
  top: 130px;
}
.note3 {
  position: absolute;
  right: 400px;
  top: 90px;
}
.note4 {
  position: absolute;
  right: 550px;
  top: 100px;
}
.note5 {
  position: absolute;
  right: 700px;
  top: 190px;
}

//------------------------

.circlesRight {
  position: relative;
  bottom: 4vmax;
  width: 100vw;
  height: 25rem;


  .smallCircleRight {
    position: absolute;
    bottom: 3vh;
    right: 0;
  }

  .bigCircleRight {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  #smallCircleRight path {
    stroke-dasharray: 340.91;
    stroke-dashoffset: 340.91;
    animation: curve 6s ease-in-out infinite;
  }

  #bigCircleRight path {
    stroke-dasharray: 542;
    stroke-dashoffset: 542;
    animation: curve 8s ease-in-out infinite;
  }
}

//-------------------------
.animationContent {
  height: 100%;

  .violinSVG {
    position: absolute;
    inset: 0;
    text-align: center;
    overflow: hidden;
    opacity: 0.3;

    svg {
      position: absolute;
      top: -65vh;
      height: calc(100% + 95vh);
    }
  }
}
