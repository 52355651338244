@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700;900&display=swap");

@import "../../../node_modules/include-media/dist/include-media";
$breakpoints: (
  phone: 320px,
  tablet: 768px,
  desktop: 1024px,
);

@font-face {
  font-family: "Kyiv Sans";
  src: url("../../assets/fonts/KyivTypeSans.woff2") format("woff2-variations");
  font-weight: 1 1000;
  font-display: swap;
}

@font-face {
  font-family: "Shantell Sans";
  src: url("../../assets/fonts/ShantellSans.woff2") format("woff2-variations");
  font-weight: 300 800;
  font-display: swap;
}

@mixin heroTxtStyle {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  color: whitesmoke;
  outline: #373737;
  font-size: 6em;
  opacity: 1;
}


section {
  height: 100vh;
  z-index: 1;
}

.text {
  height: 100vh;
  width: 80%;
  margin: 0 auto;
  color: rgb(120, 44, 44);
  font-size: 2rem;
}

.hero-main {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  animation: fade-in-hero-main 1s  ease-in forwards;
  opacity: 0;

  @keyframes fade-in-hero-main {
    to {
      opacity: 1;
    }
  }

  .hero-content {
    position: absolute;
    inset: 30% 6rem;
    z-index: 1;

    h1 {
      font-family: "Shantell Sans";
      font-size: 5vmax;
      color: #8fa3a3;
      margin-bottom: 1.2vmax;
    }

    .title {
      width: fit-content;
      display: flex;
      flex-direction: column;
      h2 {
        font-size: 2.8vmax;
        color: rgb(97, 108, 108);
      }
      .skills {
        display: flex;
        justify-content: space-between;
        font-size: 2vmax;

        p {
          padding: 0.2vmax 1vmax;
          border: 1px solid rgb(143, 163, 163);
          border-radius: 13px;
          color: rgb(97, 108, 108);
        }
      }
    }
    .contact {
      display: flex;
      align-items: center;
      gap: 1vmax;
      padding-top: 2vmax;

      h2 {
        font-size: 2.5vmax;
        color: #28834c;
      }

      .contact-icons {
        display: flex;
        gap: 10px;

        img {
          width: 2.5vmax;
        }

        button {
          cursor: pointer;
        }
      }
    }
  }

  .iconContainer {
    position: absolute;
    inset: 7vmax 40%;
    width: 100vmax;
    height: 100%;
    overflow: hidden;

    .icons {
      width: 60vw;
    }
    .icon {
      position: absolute;
      top: 0;
      width: 55vmax;
      object-fit: contain;
    }
  }

  @include media(">=phone", "<=tablet") {
    .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 55vmax;
      object-fit: contain;
    }
  }

  @include media(">=phone", "<=desktop") {
    .hero-content {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;
      top: 10vmax;
    }

    .iconContainer {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 45vmax;
      left: 10vw;
      width: 80vw;
    }
  }
}

.mail {
  background-color: transparent;
  border: none;
}
