.MuiDrawer-docked .MuiDrawer-paperAnchorDockedRight {
  background-color: #1e683c;
}

.MuiToolbar-root {
  background-color: #172534;
}


.MuiTypography-root,
.MuiSvgIcon-root {
  color: aliceblue;
}

.MuiDivider-root {
  background-color: azure;
}

.css-1dvstpp-MuiTypography-root {
  display: flex;
}
