.footerSection {
  min-height: 4rem;
  margin-top: 4rem;
  background-color: #172534;
  box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2),
    0px -4px 5px 0px rgba(0, 0, 0, 0.14), 0px -1px 10px 0px rgba(0, 0, 0, 0.12);
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .footerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    .socialIcons {
      display: flex;
      gap: 10px;

      img {
        width: 2.5vmax;
      }

      button {
        cursor: pointer;
      }
    }

    .testimonials {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      font-size: 0.8rem;
      color: rgb(194, 192, 192);

      #nameLogo {
        width: 1rem;
        margin-left: 5px;
      }
  .name {
    display: flex;
    align-items: center;
  }
      #name {
        display: flex;
        font-family: "Shantell Sans";
        font-size: 1.2rem;
        color: #8fa3a3;
        margin-right: 5px;
      }
    }
}
.scroll-top .MuiSvgIcon-root {
  color: #0187d0; 
  font-size: 2rem;
}
}
