* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  --gradient1: linear-gradient(45deg, #00ff00, #0077be, #ff0040, #9600ff);

  /* Soft and Muted */
  --gradient2: linear-gradient(45deg, #9acd32, #87ceeb, #ffc0cb, #ba55d3);

  /* Earthy and Warm */
  --gradient3: linear-gradient(45deg, #8f9779, #5e7fa2, #d6858f, #9e7cc1);
}

body {
  /* background-color: #172534; */
  background-color: #0f1c1f;
  /* background-color: #373737; */

  /* overflow: scroll; */
  /* background: #141e30; fallback for old browsers */
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgb(14, 28, 46) 0.1%,
    rgb(15, 28, 31) 94.2%
  );
  overflow-x: hidden;
}
